import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import decodeJwt from 'jwt-decode';
import { ROLE } from 'shared/src/constants/role';

export default async (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        const { username, password } = params;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                grant_type: 'password',
                username,
                password,
            }),
        };

        const response = await fetch('/api/oauth2/token', options);
        if (response.status === 400 || response.status === 403) {
            throw new Error('Érvénytelen felhasználónév vagy jelszó');
        }

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }

        const token = await response.json();

        const decodedToken = decodeJwt(token.access_token);
        const role = parseInt(decodedToken.role, 10);

        if (role !== ROLE.ADMIN && role !== ROLE.OPERATOR) {
            throw new Error('Belépésre nem jogosult');
        }

        localStorage.setItem('role', decodedToken.role);

        const accessToken = token.access_token;
        localStorage.setItem('admin-jwt', accessToken);
        localStorage.setItem('admin-jwt-role', decodedToken.role);

        // accept all username/password combinations
        return Promise.resolve();
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('admin-jwt');
        localStorage.removeItem('admin-jwt-role');
        return Promise.resolve();
    }

    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('admin-jwt');
            localStorage.removeItem('admin-jwt-role');
            return Promise.reject();
        }
        return Promise.resolve();
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return localStorage.getItem('admin-jwt') && localStorage.getItem('admin-jwt-role')
            ? Promise.resolve()
            : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('admin-jwt-role');
        return role ? Promise.resolve(parseInt(role, 10)) : Promise.reject();
    }

    return Promise.reject('Unknown method');
};
