import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    List,
    RadioButtonGroupInput,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { stringify } from 'query-string';
import { SPECIAL_FILTER_VALUES } from 'shared/src/constants/query';

const approvedChoices = [
    { id: true, name: 'Jóváhagyva' },
    { id: false, name: 'Elutasítva' },
];

const DamagePlotFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" label="Keresés" alwaysOn />
        <BooleanInput source="validated" label="Ellenőrizve" alwaysOn />
    </Filter>
);

const SiteLinkField = ({ record = {} }) => {
    if (!record.damage) {
        return null;
    }

    return (
        <a
            href={`https://generali.envimap.hu/view/damage/${record.damage.damageNumber}?damagePlotId=${record.id}`}
            target={'_blank'}
            rel="noopener noreferrer"
        >
            link
        </a>
    );
};

const NullableTextFieldWithLabel = (props) => (
    <TextField {...props} record={{ [props.source]: props.record[props.source] || '' }} />
);

export const DamagePlotList = (props) => (
    <>
        <List
            title="Táblakörvonalak ellenőrzése"
            filters={<DamagePlotFilter />}
            bulkActionButtons={false}
            sort={{ field: 'tableGeometryDate', order: 'ASC' }}
            filter={{ tableGeometryUserId: SPECIAL_FILTER_VALUES.NOT_NULL }}
            {...props}
        >
            <Datagrid rowClick="expand">
                <TextField source="id" />
                <TextField source="damage.damageNumber" label="Kárszám" sortable={false} />
                <TextField source="meparCode" label="Mepar code" />
                <DateField source="tableGeometryDate" label="Rajzolás dátuma" />
                <TextField source="tableGeometryUser.name" label="Rajzoló" />
                <SiteLinkField />
                <BooleanField source="validated" label="Ellenőrizve" />
                <BooleanField source="approved" label="Jóváhagyva" />
                <NullableTextFieldWithLabel source="comment" label="Megjegyzés" />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

const validateDamagePlotEdit = (values) => {
    const errors = {};
    if (!_.has(values, 'approved')) {
        errors.approved = ['A jóváhagyás / elutasítás kitöltése kötelező'];
    }
    return errors;
};

const DamagePlotEditActions = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const damagePlotRedirect = (basePath, id, data) =>
    `/damagePlots?${stringify({
        filter: JSON.stringify({ validated: false }),
    })}`;

export const DamagePlotEdit = (props) => {
    return (
        <Edit undoable={false} title={''} {...props}>
            <SimpleForm
                validate={validateDamagePlotEdit}
                toolbar={<DamagePlotEditActions />}
                redirect={damagePlotRedirect}
            >
                <Divider style={{ width: '100%' }} />
                <Grid container spacing={3} style={{ width: '100%' }}>
                    <Grid item xs={6}>
                        <RadioButtonGroupInput
                            label="Táblakörvonal jóváhagyva"
                            source="approved"
                            row={false}
                            choices={approvedChoices}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            source="comment"
                            label="Megjegyzés"
                            multiline={true}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
