import React from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink, usePermissions } from 'react-admin';
import CloudIcon from '@material-ui/icons/Cloud';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import MessageIcon from '@material-ui/icons/Message';
import TableChartIcon from '@material-ui/icons/TableChart';
import { stringify } from 'query-string';
import { ROLE } from 'shared/src/constants/role';

const Menu = ({ onMenuClick }) => {
    const open = useSelector((state) => state.admin.ui.sidebarOpen);

    const { permissions } = usePermissions();

    return (
        <>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={{
                    pathname: '/images',
                    search: stringify({
                        filter: JSON.stringify({ validated: false }),
                    }),
                }}
                primaryText={'Felvételek ellenőrzése'}
                leftIcon={<CloudIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {permissions === ROLE.ADMIN && (
                <MenuItemLink
                    to={{
                        pathname: '/alerts',
                        search: stringify({
                            filter: JSON.stringify({ validated: false }),
                        }),
                    }}
                    primaryText={'Riasztások ellenőrzése'}
                    leftIcon={<TrendingDownIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            )}
            {permissions === ROLE.ADMIN && (
                <MenuItemLink
                    to={{
                        pathname: '/users',
                    }}
                    primaryText={'Ügyfelek értesítése'}
                    leftIcon={<MessageIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            )}
            {permissions === ROLE.ADMIN && (
                <MenuItemLink
                    to={{
                        pathname: '/damagePlots',
                        search: stringify({
                            filter: JSON.stringify({ validated: false }),
                        }),
                    }}
                    primaryText={'Táblakörvonalak'}
                    leftIcon={<TableChartIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            )}
        </>
    );
};

export default Menu;
