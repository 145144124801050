import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import ModalImage from 'react-modal-image';
import { makeStyles } from '@material-ui/core/styles';

const IMAGE_TYPES = {
    TCI: 1,
    SCL: 2,
    BMI: 3,
    BMI_DIFF: 4,
    POT_PLANT: 5,
    BMI_DIFF_SIG: 6,
    CROP_MAP: 7,
    IDOKEP_AGG: 8,
};

function getFileNameByPostfix(fileNames, postFix) {
    return fileNames.find((fileName) => fileName.indexOf(`_${postFix}.`) > -1);
}

function getFileName(imageType, fileNames) {
    switch (imageType) {
        case IMAGE_TYPES.TCI:
            return getFileNameByPostfix(fileNames, 'TCI');
        case IMAGE_TYPES.SCL:
            return getFileNameByPostfix(fileNames, 'SCL');
        case IMAGE_TYPES.BMI:
            return getFileNameByPostfix(fileNames, 'BMI');
        case IMAGE_TYPES.BMI_DIFF:
            return getFileNameByPostfix(fileNames, 'BMIdiff');
        case IMAGE_TYPES.POT_PLANT:
            return getFileNameByPostfix(fileNames, 'PotPlant');
        case IMAGE_TYPES.BMI_DIFF_SIG:
            return getFileNameByPostfix(fileNames, 'BMIdiffSig');
        case IMAGE_TYPES.CROP_MAP:
            return getFileNameByPostfix(fileNames, 'CropMap');
        case IMAGE_TYPES.IDOKEP_AGG:
            return getFileNameByPostfix(fileNames, 'IdokepAgg');
        default:
            throw new Error(`Unknown image type ${imageType}`);
    }
}

function splitImages(imagesString) {
    return imagesString && imagesString.split(';');
}

const imageMetaDataProperties = ['cloudCategory', 'cloudy', 'useful', 'soil', 'vegetation'];

function getImageMetaData(meta) {
    return imageMetaDataProperties.map((property) => ({ key: property, value: meta[property] }));
}

const alertMetaDataProperties = [
    'DayDiff',
    'RadarEvent',
    'RadarAvailable',
    '',
    'NDVInewQ0',
    'NDVInewQ1',
    'NDVInewQ2',
    'NDVInewQ3',
    'NDVInewQ4',
    '',
    'NDVIoldQ0',
    'NDVIoldQ1',
    'NDVIoldQ2',
    'NDVIoldQ3',
    'NDVIoldQ4',
    '',
    'NDVIdiffQ0',
    'NDVIdiffQ1',
    'NDVIdiffQ3',
    'NDVIdiffQ2',
    'NDVIdiffQ4',
    '',
    'NdviPlantQ0',
    'NdviPlantQ4',
    'NdviPlantCatLow',
    'NdviPlantCatOpt',
    'NdviPlantCatHigh',
    '',
    'NdviChangePlantQ1',
    'NdviChangePlantQ3',
    'NdviChangePlantCatLow',
    'NdviChangePlantCatOpt',
    'NdviChangePlantCatHigh',
];

function getAlertMetaData(meta) {
    return alertMetaDataProperties.map((property) => {
        if (property === '') {
            return { key: null, value: null };
        }

        return { key: property, value: meta[property] };
    });
}

const useStyles = makeStyles({
    image: { maxHeight: 'calc(calc(100vh / 3) - 20px) !important' },
});

function ImageField(params) {
    const { title, fileName } = params;
    const classes = useStyles();
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '12px', marginBottom: '5px' }}>{title}</div>
            <ModalImage
                className={classes.image}
                small={`pdfs/${fileName}`}
                large={`pdfs/${fileName}`}
                alt={title}
            />
        </div>
    );
}

export function ImageChessboardField(params) {
    const { record = {} } = params;
    const beforeImages = record.beforeImage && splitImages(record.beforeImage.fileName);
    const images = splitImages(record.fileName);

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
            <Grid container spacing={3} style={{ width: '100%' }}>
                {beforeImages && (
                    <>
                        <Grid item xs={4}>
                            <ImageField
                                title={'Valós színes kép (előtte)'}
                                fileName={getFileName(IMAGE_TYPES.TCI, beforeImages)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ImageField
                                title={'Osztályozott kép (előtte)'}
                                fileName={getFileName(IMAGE_TYPES.SCL, beforeImages)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ImageField
                                title={'NDVI kép (előtte)'}
                                fileName={getFileName(IMAGE_TYPES.BMI, beforeImages)}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={4}>
                    <ImageField
                        title={'Valós színes kép'}
                        fileName={getFileName(IMAGE_TYPES.TCI, images)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageField
                        title={'Osztályozott kép'}
                        fileName={getFileName(IMAGE_TYPES.SCL, images)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageField
                        title={'NDVI kép'}
                        fileName={getFileName(IMAGE_TYPES.BMI, images)}
                    />
                </Grid>
            </Grid>
            <table style={{ fontSize: '12px', padding: '10px 0px 10px 0px' }}>
                {record.meta &&
                    getImageMetaData(record.meta).map(({ key, value }) => (
                        <tr>
                            <td>{key}:</td>
                            <td>{value}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}

export function AlertChessboardField(params) {
    const { record = {} } = params;
    const beforeImages = record.beforeImage && splitImages(record.beforeImage.fileName);
    const images = record.image && splitImages(record.image.fileName);
    const alertImages = record.image && splitImages(record.fileName);

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
            <Grid container spacing={1} style={{ width: '100%' }}>
                {beforeImages && (
                    <>
                        <Grid item xs={2}>
                            <ImageField
                                title={'Valós színes kép (előtte)'}
                                fileName={getFileName(IMAGE_TYPES.TCI, beforeImages)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ImageField
                                title={'Osztályozott kép (előtte)'}
                                fileName={getFileName(IMAGE_TYPES.SCL, beforeImages)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ImageField
                                title={'NDVI kép (előtte)'}
                                fileName={getFileName(IMAGE_TYPES.BMI, beforeImages)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ImageField
                                title={'NDVI különbség'}
                                fileName={getFileName(IMAGE_TYPES.BMI_DIFF, alertImages)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ImageField
                                title={'Potenciális növényborítás'}
                                fileName={getFileName(IMAGE_TYPES.POT_PLANT, alertImages)}
                            />
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </>
                )}
                <Grid item xs={2}>
                    <ImageField
                        title={'Valós színes kép'}
                        fileName={getFileName(IMAGE_TYPES.TCI, images)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ImageField
                        title={'Osztályozott kép'}
                        fileName={getFileName(IMAGE_TYPES.SCL, images)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ImageField
                        title={'NDVI kép'}
                        fileName={getFileName(IMAGE_TYPES.BMI, images)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ImageField
                        title={'Időszakra jellemző csökkenés'}
                        fileName={getFileName(IMAGE_TYPES.BMI_DIFF_SIG, alertImages)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ImageField
                        title={'Vetéstérkép / Időkép'}
                        fileName={
                            getFileName(IMAGE_TYPES.CROP_MAP, alertImages) ||
                            getFileName(IMAGE_TYPES.IDOKEP_AGG, alertImages)
                        }
                    />
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
            <table style={{ fontSize: '12px', padding: '10px 0px 10px 0px' }}>
                {record.meta &&
                    getAlertMetaData(record.meta).map(({ key, value }) => (
                        <tr>
                            <td>
                                {key ? `${key}:` : <div style={{ height: '10px;' }}>&nbsp;</div>}
                            </td>
                            <td>{value}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}
