import React, { Fragment } from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    EmailField,
    Filter,
    List,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import { ROLE } from 'shared/src/constants/role';
import SendSignupNotificationButton from './action/SendSignupNotificationButton';
import SendAlertButton from './action/SendAlertButton';
import { SPECIAL_FILTER_VALUES } from 'shared/src/constants/query';
import moment from 'moment';
import { tokenModule } from 'shared/src/modules/tokenModule';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { IMAGE_CATEGORIES } from 'shared/src/constants/image';
import { MESSAGE_CONTENT_TYPE } from 'shared/src/constants/message';

const BulkActionButtons = (props) => (
    <Fragment>
        <SendSignupNotificationButton label="Regisztrációs link küldése" {...props} />
        <SendAlertButton label="Riasztás küldése" {...props} />
    </Fragment>
);

const UserListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" label="Keresés" alwaysOn />
        <SelectInput
            source="operatorUserId"
            label="Felvétel státusz"
            choices={[
                { id: SPECIAL_FILTER_VALUES.NULL, name: 'Felvétel ellenőrzésre vár' },
                { id: SPECIAL_FILTER_VALUES.NOT_NULL, name: 'Felvétel ellenőrizve' },
            ]}
        />
    </Filter>
);

const NotificationSentDateField = ({ record = {} }) => {
    const notifications = record.messages.filter(
        (message) => message.contentType === MESSAGE_CONTENT_TYPE.REGISTRATION_NOTIFICATION
    );
    const lastNotification = notifications.length > 0 && notifications[0];

    return (
        <span>
            {lastNotification ? moment(lastNotification.createdAt).format('YYYY. MM. DD.') : '-'}
        </span>
    );
};

const LastAlertField = ({ record = {} }) => {
    const alerts = record.messages.filter(
        (message) => message.contentType === MESSAGE_CONTENT_TYPE.ALERT
    );
    const lastAlert = alerts.length > 0 && alerts[0];

    return <span>{lastAlert ? moment(lastAlert.createdAt).format('YYYY. MM. DD.') : '-'}</span>;
};

const NewAlertDateField = ({ record = {} }) => {
    const alerts = record.messages.filter(
        (message) => message.contentType === MESSAGE_CONTENT_TYPE.ALERT
    );
    const lastAlert = alerts.length > 0 && alerts[0];

    const alertDates = farmerModule.getAlertDates(record.mepars);

    const newAlertDates = lastAlert
        ? alertDates.filter((alertDate) =>
              moment(alertDate).isSameOrAfter(moment(lastAlert.createdAt))
          )
        : alertDates;

    const newAlertDate = newAlertDates.length > 0 && newAlertDates[0];

    return <span>{newAlertDate ? moment(newAlertDate).format('YYYY. MM. DD.') : '-'}</span>;
};

const MeparsField = ({ record = {} }) => {
    const totalMepars = record.mepars.length;
    return <span>{totalMepars}</span>;
};

const MeparsCheckedField = ({ record = {} }) => {
    const checkedMepars = record.mepars.filter((mepar) => mepar.status === 'checked').length;
    return <span>{checkedMepars}</span>;
};

const MeparsBadCodeField = ({ record = {} }) => {
    const badCodeMepars = record.mepars.filter((mepar) => mepar.status === 'badcode').length;
    return <span>{badCodeMepars}</span>;
};

const NdvisField = ({ record = {} }) => {
    const ndviCount = record.mepars.filter(
        (mepar) =>
            mepar.status === 'checked' &&
            mepar.products.some(
                (product) =>
                    product.type === 'Chronology' &&
                    product.subType === 'BMI' &&
                    product.image &&
                    product.image.category === IMAGE_CATEGORIES.CLEAR &&
                    product.image.validated
            )
    ).length;
    return <span>{ndviCount}</span>;
};

const LinkField = ({ record = {} }) => {
    const compoundLinkToken = tokenModule.composeCompoundLinkToken(record);
    return (
        <a
            href={`https://karriasztas.hu/t/${compoundLinkToken}?admin=true`}
            target={'_blank'}
            rel="noopener noreferrer"
        >
            link
        </a>
    );
};

const AlertLinkField = ({ record = {} }) => {
    const compoundLinkToken = tokenModule.composeCompoundLinkToken(record);
    const alertDates = farmerModule.getAlertDates(record.mepars);
    const latestAlertDate = alertDates.length > 0 && alertDates[0];
    return (
        <a
            href={`https://karriasztas.hu/t/${compoundLinkToken}/alert/${latestAlertDate}?admin=true`}
            target={'_blank'}
            rel="noopener noreferrer"
        >
            link
        </a>
    );
};

export const UserList = (props) => (
    <>
        <List
            filter={{ role: ROLE.FARMER, enabled: true }}
            title="Ügyfelek"
            bulkActionButtons={<BulkActionButtons />}
            filters={<UserListFilter />}
            {...props}
        >
            <Datagrid>
                <TextField source="id" />
                <EmailField source="email" label="Email" />
                <EmailField source="phone" label="Telefonszám" />
                <MeparsField source="mepars" label="Mepár" sortable={false} />
                <MeparsCheckedField source="mepars" label="Kész" sortable={false} />
                <MeparsBadCodeField source="mepars" label="Rossz kód" sortable={false} />
                <NdvisField source="mepars" label="Felhőmentes" sortable={false} />
                <NotificationSentDateField label="Invitáció" sortable={false} />
                <LastAlertField label="Riasztás" sortable={false} />
                <NewAlertDateField label="Új riasztás" sortable={false} />
                <LinkField label="Admin link" sortable={false} />
                <AlertLinkField label="Admin alert link" sortable={false} />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

const UserEditActions = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export const UserEdit = (props) => (
    <Edit undoable={false} title={''} {...props}>
        <SimpleForm toolbar={<UserEditActions />}>
            <TextInput source="email" label="Email" />
            <TextInput source="phone" label="Telefonszám" />
        </SimpleForm>
    </Edit>
);
