import React from 'react';
import { Admin, Resource } from 'react-admin';
import i18nProvider from '../services/i18nProvider';
import dataProvider from '../services/dataProvider';
import authProvider from '../services/authProvider';
import { ROLE } from 'shared/src/constants/role';
import { UserEdit, UserList } from '../components/users';
import Layout from '../components/layout/Layout';
import Dashboard from '../components/dashboard/dashboard';
import { ImageEdit, ImageList } from '../components/image';
import { AlertEdit, AlertList } from '../components/alerts';
import { DamagePlotEdit, DamagePlotList } from '../components/damagePlots';

export default function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            layout={Layout}
            dashboard={Dashboard}
        >
            {(permissions) => [
                permissions === ROLE.ADMIN || permissions === ROLE.OPERATOR ? (
                    <Resource name={'images'} list={ImageList} edit={ImageEdit} />
                ) : null,
                permissions === ROLE.ADMIN ? (
                    <Resource name={'alerts'} list={AlertList} edit={AlertEdit} />
                ) : null,
                permissions === ROLE.ADMIN ? (
                    <Resource name={'users'} list={UserList} edit={UserEdit} />
                ) : null,
                permissions === ROLE.ADMIN ? (
                    <Resource name={'damagePlots'} list={DamagePlotList} edit={DamagePlotEdit} />
                ) : null,
            ]}
        </Admin>
    );
}
