import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    List,
    RadioButtonGroupInput,
    SaveButton,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { AlertChessboardField } from './field/ImageField';
import { stringify } from 'query-string';

const approvedChoices = [
    { id: true, name: 'Jóváhagyva' },
    { id: false, name: 'Elutasítva' },
];

const AlertFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" label="Keresés" alwaysOn />
        <BooleanInput source="validated" label="Ellenőrizve" />
    </Filter>
);

const NullableTextField = ({ source, record = {} }) => <span>{record[source]}</span>;

const NullableTextFieldWithLabel = (props) => (
    <TextField {...props} record={{ [props.source]: props.record[props.source] || '' }} />
);

export const AlertList = (props) => (
    <>
        <List
            title="Változások ellenőrzése"
            filters={<AlertFilter />}
            bulkActionButtons={false}
            sort={{ field: 'date', order: 'ASC' }}
            {...props}
        >
            <Datagrid rowClick="expand" expand={<AlertShow />}>
                <TextField source="id" />
                <DateField source="date" label="Felvétel dátuma" />
                <DateField source="dateBefore" label="Előző felvétel dátuma" />
                <TextField source="meparCode" label="MePAR" />
                <NullableTextField source="plantCategory" label="Növény kategória" />
                <NullableTextField source="policyPlotId" label="Kötvénytábla Id" />
                <BooleanField source="validated" label="Ellenőrizve" />
                <BooleanField source="approved" label="Jóváhagyva" />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

const validateAlertEdit = (values) => {
    const errors = {};
    if (!_.has(values, 'approved')) {
        errors.approved = ['A jóváhagyás / elutasítás kitöltése kötelező'];
    }
    return errors;
};

const AlertEditActions = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const alertRedirect = (basePath, id, data) =>
    `/alerts?${stringify({
        filter: JSON.stringify({ validated: false }),
    })}`;

export const AlertEdit = (props) => {
    const { record = {} } = props;

    return (
        <Edit undoable={false} title={''} {...props}>
            <SimpleForm
                validate={validateAlertEdit}
                toolbar={<AlertEditActions />}
                redirect={alertRedirect}
            >
                <AlertChessboardField />
                <Divider style={{ width: '100%' }} />
                <Grid container spacing={3} style={{ width: '100%' }}>
                    <Grid item xs={6}>
                        <RadioButtonGroupInput
                            label="Riasztásra jóváhagyva"
                            source="approved"
                            row={false}
                            choices={approvedChoices}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ margin: '8px 0px 4px 0px' }}>
                            <div>Operátor megjegyzés: {record.comment || '-'}</div>
                        </div>
                        <TextInput
                            source="comment"
                            label="Megjegyzés"
                            multiline={true}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export const AlertShow = (props) => {
    return (
        <Show title={''} {...props}>
            <SimpleShowLayout>
                <AlertChessboardField />
                <Divider style={{ width: '100%' }} />
                <NullableTextFieldWithLabel source="comment" label="Megjegyzés" addLabel={true} />
            </SimpleShowLayout>
        </Show>
    );
};
