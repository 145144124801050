import React, { useState } from 'react';
import { Button as RAButton, fetchUtils } from 'react-admin';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Button from '@material-ui/core/Button/Button';

const SendSignupNotificationButton = (props) => {
    const { selectedIds } = props;
    const [open, setOpen] = useState(false);

    async function handleClick() {
        const token = localStorage.getItem('admin-jwt');
        const options = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify({
                userIds: selectedIds,
            }),
        };

        await fetchUtils
            .fetchJson('/api/admin/sendSignupNotifications', options)
            .then((result) => console.log(result));
        setOpen(true);
    }

    function handleClickDialogOk() {
        setOpen(false);
    }

    return (
        <>
            <RAButton label="Regisztrációs üzenet küldése" onClick={handleClick}>
                <ExitToAppIcon />
            </RAButton>
            <Dialog open={open} onClose={handleClickDialogOk}>
                <DialogTitle>Üzenet küldés</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Az üzenet(ek) kiküldése megtörtént.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickDialogOk} color="primary" autofocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SendSignupNotificationButton;
