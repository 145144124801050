import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
    en: { ...englishMessages, resetViews: 'Reset' },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);

export default i18nProvider;
