import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    List,
    NumberField,
    RadioButtonGroupInput,
    SaveButton,
    SelectField,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    NumberInput,
    TextInput,
    Toolbar,
} from 'react-admin';
import { SPECIAL_FILTER_VALUES } from 'shared/src/constants/query';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { IMAGE_CATEGORIES } from 'shared/src/constants/image';
import { stringify } from 'query-string';
import { ImageChessboardField } from './field/ImageField';

const categoryChoices = [
    { id: IMAGE_CATEGORIES.CLOUDY, name: 'Felhős' },
    { id: IMAGE_CATEGORIES.CLEAR, name: 'Felhőmentes' },
];

const ImageFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" label="Keresés" alwaysOn />
        <BooleanInput source="validated" label="Ellenőrizve" />
        <NumberInput source="operatorUserId" label="Ellenőrizte" />
    </Filter>
);

const NullableTextField = ({ source, record = {} }) => <span>{record[source]}</span>;

const NullableTextFieldWithLabel = (props) => (
    <TextField {...props} record={{ [props.source]: props.record[props.source] || '' }} />
);

const CommentField = ({ source, record = {} }) => (
    <span>
        {(record[source] || '').length > 20 ? `${record[source].substr(0, 20)}...` : record[source]}
    </span>
);

export const ImageList = (props) => (
    <>
        <List
            title="Felvételek ellenőrzése"
            filter={{ fileName: SPECIAL_FILTER_VALUES.NOT_NULL }}
            filters={<ImageFilter />}
            bulkActionButtons={false}
            sort={{ field: 'date', order: 'ASC' }}
            {...props}
        >
            <Datagrid rowClick="expand" expand={<ImageShow />}>
                <TextField source="id" />
                <DateField source="date" label="Dátum" />
                <TextField source="meparCode" label="MePAR" />
                <NullableTextField source="policyPlotId" label="Kötvénytábla Id" />
                <BooleanField source="validated" label="Ellenőrizve" />
                <SelectField
                    source="category"
                    label="Kategória"
                    choices={[
                        { id: IMAGE_CATEGORIES.CLEAR, name: 'Felhőmentes' },
                        { id: IMAGE_CATEGORIES.CLOUDY, name: 'Felhős' },
                    ]}
                />
                <CommentField source="comment" label="Megjegyzés" />
                <DateField source="updatedAt" label="Utolsó módosítás" showTime={true} />
                <NumberField source="operatorUserId" label="Ellenőrizte" />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

const validateImageEdit = (values) => {
    const errors = {};
    if (!values.category) {
        errors.category = ['A kategória kitöltése kötelező'];
    }
    return errors;
};

const ImageEditActions = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const imageRedirect = (basePath, id, data) =>
    `/images?${stringify({
        filter: JSON.stringify({ validated: false }),
    })}`;

export const ImageEdit = (props) => (
    <Edit undoable={false} title={''} {...props}>
        <SimpleForm
            validate={validateImageEdit}
            toolbar={<ImageEditActions />}
            redirect={imageRedirect}
        >
            <ImageChessboardField />
            <Divider style={{ width: '100%' }} />
            <Grid container spacing={3} style={{ width: '100%' }}>
                <Grid item xs={6}>
                    <RadioButtonGroupInput
                        label="Kategória"
                        source="category"
                        row={false}
                        choices={categoryChoices}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="comment"
                        label="Megjegyzés"
                        multiline={true}
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const ImageShow = (props) => (
    <Show title={''} {...props}>
        <SimpleShowLayout>
            <ImageChessboardField />
            <Divider style={{ width: '100%' }} />
            <NullableTextFieldWithLabel source="comment" label="Megjegyzés" addLabel={true} />
        </SimpleShowLayout>
    </Show>
);
