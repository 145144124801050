import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

function httpClient(url, options = {}) {
    const token = localStorage.getItem('admin-jwt');

    options.headers = new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    });

    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider('/api/admin', httpClient);

export default dataProvider;
